import { graphql } from 'gatsby';
import * as React from 'react';
import ContactInfo from '../components/global/contact-info/contact-info';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import GridGallery from '../components/global/grid-gallery/grid-gallery';
import HeaderAndText from '../components/global/header-and-text/header-and-text';
import Layout from '../components/global/layout/layout';

export const pageQuery = graphql`
  query RestaurantQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    restaurant_indoor: file(name: {eq: "restaurant-3"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ahnensaal: file(name: {eq: "ahnensaal"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gallery: allFile(filter: {name: {glob: "restaurant-3|restaurant-outdoor-*|westerburg_platzhalter_gold"}}, sort: {fields: uid}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            originalName
          }
        }
      }
    }
    restaurant: prismicRestaurant {
      data {
        menu_link {
          url
        }
      }
    }
  }
`

export default props => {
  const galleryData = props.data.gallery.nodes.map(node => {
    return {
      fluid: node.childImageSharp.fluid
    };
  })
  return (
    <Layout wrapperClassName="bg-pattern-gold"
            title="Restaurant"
            description="Das Restaurant Semperfrey stellt mit seiner angeschlossenen Terrasse das Herzstück unseres Hauses dar. Genießen Sie im historischen und familiären Ambiente des Restaurants unsere kulinarischen Köstlichkeiten,"
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <div itemScope itemType="https://schema.org/Restaurant">
        <meta itemProp="name" content="Das Restaurant Semperfrey"></meta>
        <meta itemProp="acceptsReservations" content="true"></meta>
        <meta itemProp="hasMenu" content={props.data.restaurant.data.menu_link.url}></meta>
        <FullWidthImage tag="header" fluid={props.data.restaurant_indoor.childImageSharp.fluid}/>
        <HeaderAndText
          heading="Das Restaurant Semperfrey"
          subtitle="<br><span style='font-weight:700'>Unsere Öffnungszeiten</span><br><time itemprop='openingHours' dateTime='Mo,Tu,We,Th,Fr,Sa 17:30-23:59'>Montag bis Samstag ab 17:30</time><br><time itemProp='openingHours' dateTime='Su 11:30-14:00 17:30-23:59'>Sonn- und Feiertags von 11:30 - 14:00 und ab 17:30</time>"
          text={[
            "Das Restaurant Semperfrey stellt mit seiner angeschlossenen Terrasse das Herzstück unseres Hauses dar. Genießen Sie im historischen und familiären Ambiente des Restaurants unsere verlockenden kulinarischen Köstlichkeiten und ausgesuchten Wein und Bierspezialitäten.",
            "Das kulinarische Angebot unserer Küche wird bestimmt von der sorgfältigen Auswahl der Zutaten. Hierbei spielt die Frische und Herkunft der Produkte eine besondere Rolle. Alles in unserem Hause spricht seine eigene Sprache. Von den uralten Mauern bis hin zu unseren – mit viel Liebe zum Detail gedeckten – Tischen: Sie werden nichts finden, was überflüssig zu sein scheint, aber alles, was man braucht, um einmal richtig abschalten zu können.",
            "Herzlich willkommen zum Feierabend."
          ]}
        />
        <GridGallery images={galleryData}/>
        <HeaderAndText
          heading="Unsere Speisen- &amp; Getränkekarte"
          text={[
            "Genießen Sie im historischen Ambiente unseres Hauses die verlockenden kulinarischen Köstlichkeiten und ausgesuchten Weine.",
            "Mit der Eröffnung der Schlossrestauration greifen wir auf eine sehr alte Tradition zurück. Bereits in der Mitte des 18. Jahrhunderts gab es auf Schloss Westerburg eine Gastwirtschaft. Dem Hofmann Peter Rosenstein wurde 1744 gewährt, im Wirtshaus der Burg Wein, Bier und Schnaps auszuschenken sowie selbstgebackenes Brot und Wecke feilzubieten. Damals wurde das Bier hinter den dicken Mauern des eigenen Brauhauses selbst gebraut.",
            "Heute brauen wir das Bier zwar nicht mehr selbst, Gastlichkeit allerdings steht für uns mehr denn je im Vordergrund. Gerne erfüllen wir Ihre individuellen Wünsche. Bei Fragen, Anregungen aber auch Kritik stehen wir Ihnen jederzeit zur Verfügung – sprechen Sie uns an."
          ]}
          link={{ to: props.data.restaurant.data.menu_link.url, text: 'Speisen- &amp; Getränkekarte ansehen', target: '_blank' }}
        />
        <ContactInfo bgClass="bg-pattern-blue"/>
        <FullWidthImage link={{ to: '/hochzeit', text: 'Ihre Hochzeit auf Schloss Westerburg' }} tag="section" fluid={props.data.ahnensaal.childImageSharp.fluid}/>
      </div>
    </Layout>
  );
}
